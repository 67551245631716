#lien-front-office {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.25em;
	font-weight: bold;
}

#lien-front-office a {
	background-color: var(--button-bg-color);
	border: none;
	border-radius: 6px;
	color: var(--button-text-color);
	cursor: pointer;
	padding: 0.5em 0.75em;
	transition: background-color 0.3s ease;
}

#lien-front-office a:hover {
	text-decoration: none;
}

#lien-front-office a:visited {
	color: var(--button-text-color);
}