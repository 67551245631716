#lien-dashboard {
	display: flex;
	align-items: center;
	height: 100%;
	margin: 0;
}

#lien-dashboard svg {
	color: var(--bordeaux);
	height: 28px;
	width: 28px;
}

/* ---------------------------------------- */

/* medium display (ajustements) */

@media only screen and (min-width: 1140px) {
	#lien-dashboard svg {
		height: 38px;
		width: 38px;
	}
}